import { createContext, useContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

// mui components
import { Button, Container, Stack, Typography } from '@mui/material';

// libraries
import { useApolloClient, gql } from '@apollo/client';

// services
import { ErrorResponse, ProcessFailure, ProcessResult, ProcessSuccess } from '../services/handleError';

// config
import { TOP_BASE_URL, MANAGEMENT_API_IDENTIFIER } from '../config/env';
import { Orientation, Size, Template } from '../config/types';

// providers
import { LoadingContext } from './loadingProvider';

/**
 * Providerが、他のコンポーネントに共有するテンプレート情報・関数
 */
export const TemplatesContext = createContext<{
  templates: Template[] | undefined;
  getTemplate: (templateId: string) => Template | null;
  createTemplate: (
    templateName: string,
    size: Size,
    orientation: Orientation
  ) => Promise<ProcessResult<Template, ErrorResponse>>;
  deleteTemplate: (templateId: string) => Promise<ProcessResult<boolean, ErrorResponse>>;
}>({
  templates: [],
  getTemplate: () => ({} as Template),
  createTemplate: async () => Promise.resolve(new ProcessSuccess({} as Template)),
  deleteTemplate: async () => Promise.resolve(new ProcessSuccess(true)),
});

/**
 * 他のコンポーネントにテンプレート情報を共有するためのProvider
 */
export const TemplatesProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const apolloClient = useApolloClient();
  const { user, logout, getAccessTokenSilently } = useAuth0();
  const { showLoading, hideLoading } = useContext(LoadingContext);

  const [templates, setTemplates] = useState<Template[]>();

  // useEffectの処理を管理するためのstate
  const [initialized, setInitialized] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!user) return;
    if (error) return;
    if (templates) return;
    if (initialized) return;

    const fetchTemplates = async (
      _templates: Template[] = [],
      nextToken: string | null = null
    ): Promise<{ templates: Template[] }> => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: MANAGEMENT_API_IDENTIFIER,
        },
      });

      const option = {
        query: gql`
          query ListUserTemplates($nextToken: String) {
            listUserTemplates(filter: {}, nextToken: $nextToken) {
              items {
                template_id
                name
                user_id
                replacements
                status
                size
                orientation
                created_at
                updated_at
              }
              nextToken
            }
          }
        `,
        variables: { nextToken },
        context: {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        },
      };
      const { data } = await apolloClient.query<{
        listUserTemplates: {
          items: Template[];
          nextToken: string | null;
        };
      }>(option);

      const newTemplates = [..._templates, ...data.listUserTemplates.items];
      if (data.listUserTemplates.nextToken) {
        const { templates: _newTemplates } = await fetchTemplates(newTemplates, data.listUserTemplates.nextToken);
        return { templates: _newTemplates };
      }
      return { templates: newTemplates };
    };

    const initialize = async () => {
      try {
        showLoading();

        // テンプレート情報を取得
        const { templates: fetchedTemplates } = await fetchTemplates();

        // fetchedTemplatesをcreated_atの降順でソート
        fetchedTemplates.sort((a, b) => {
          if (a.created_at < b.created_at) return 1;
          if (a.created_at > b.created_at) return -1;
          return 0;
        });

        setTemplates(fetchedTemplates);
        setInitialized(true);
      } finally {
        hideLoading();
      }
    };

    initialize().catch((err) => {
      console.error(err);
      setError(true);
    });
  }, [error, apolloClient, getAccessTokenSilently, user, templates, initialized, showLoading, hideLoading]);

  // template_idを指定してテンプレート情報を取得
  const getTemplate = useCallback(
    (templateId: string) => {
      if (!templates) return null;
      const template = templates.find((_template) => _template.template_id === templateId);
      if (!template) return null;
      return template;
    },
    [templates]
  );

  /**
   * テンプレートをDBに作成
   * - 以下の属性は、固定値で作成
   * - replacements: []
   * - status: processing
   */
  const createTemplateToDB = useCallback(
    async (
      templateName: string,
      size: Size,
      orientation: Orientation
    ): Promise<ProcessResult<Template, ErrorResponse>> => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: MANAGEMENT_API_IDENTIFIER,
          },
        });

        const option = {
          mutation: gql`
            mutation CreateTemplate(
              $templateName: String!
              $status: Status!
              $size: Size!
              $orientation: Orientation!
            ) {
              createTemplate(
                input: {
                  replacements: []
                  name: $templateName
                  status: $status
                  size: $size
                  orientation: $orientation
                }
              ) {
                template_id
                name
                user_id
                replacements
                status
                size
                orientation
                created_at
                updated_at
              }
            }
          `,
          variables: {
            templateName,
            status: 'processing',
            size,
            orientation,
          },
          context: {
            headers: {
              authorization: `Bearer ${accessToken}`,
            },
          },
        };

        const { data, errors } = await apolloClient.mutate<{
          createTemplate: Template;
        }>(option);

        if (errors) throw new Error(errors[0].message);
        if (!data) throw new Error('テンプレートを作成できませんでした。');

        return new ProcessSuccess(data.createTemplate);
      } catch (err) {
        return new ProcessFailure(
          new ErrorResponse(createTemplateToDB.name, 500, 'CREATE_TEMPLATE_ERROR', err as Error)
        );
      }
    },
    [apolloClient, getAccessTokenSilently]
  );

  /**
   * テンプレートをDBから削除
   */
  const deleteTemplateFromDB = useCallback(
    async (templateId: string): Promise<ProcessResult<boolean, ErrorResponse>> => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: MANAGEMENT_API_IDENTIFIER,
          },
        });

        const option = {
          mutation: gql`
            mutation DeleteTemplate($templateId: ID!) {
              deleteTemplate(input: { template_id: $templateId }) {
                template_id
              }
            }
          `,
          variables: {
            templateId,
          },
          context: {
            headers: {
              authorization: `Bearer ${accessToken}`,
            },
          },
        };

        const { data, errors } = await apolloClient.mutate<{
          deleteTemplate: Template;
        }>(option);

        if (errors) throw new Error(errors[0].message);
        if (!data) throw new Error('テンプレートを削除できませんでした。');

        return new ProcessSuccess(true);
      } catch (err) {
        return new ProcessFailure(
          new ErrorResponse(deleteTemplateFromDB.name, 500, 'DELETE_TEMPLATE_ERROR', err as Error)
        );
      }
    },
    [apolloClient, getAccessTokenSilently]
  );

  // テンプレート情報をDBに作成し、stateに反映
  const createTemplate = useCallback(
    async (
      templateName: string,
      size: Size,
      orientation: Orientation
    ): Promise<ProcessResult<Template, ErrorResponse>> => {
      const createRes = await createTemplateToDB(templateName, size, orientation);
      if (createRes.isFailure()) return createRes;
      const newTemplates = templates ? [createRes.value, ...templates] : [createRes.value];
      setTemplates(newTemplates);

      return createRes;
    },
    [templates, createTemplateToDB]
  );

  // テンプレート情報をDBから削除し、stateから削除
  const deleteTemplate = useCallback(
    async (templateId: string): Promise<ProcessResult<boolean, ErrorResponse>> => {
      const deleteRes = await deleteTemplateFromDB(templateId);
      if (deleteRes.isFailure()) return deleteRes;
      const newTemplates = templates ? templates.filter((template) => template.template_id !== templateId) : [];
      setTemplates(newTemplates);

      return deleteRes;
    },
    [templates, deleteTemplateFromDB]
  );

  // Providerのvalueに渡すオブジェクトをメモ化
  const value = useMemo(
    () => ({ templates, getTemplate, createTemplate, deleteTemplate }),
    [templates, getTemplate, createTemplate, deleteTemplate]
  );

  // エラーが発生した場合、エラー画面を表示
  if (error)
    return (
      <Stack direction="column" maxWidth="sm" mx="auto" spacing={4}>
        <Stack direction="column" spacing={2}>
          <Typography>テンプレート情報取得時、エラーが発生しました。</Typography>
          <Typography>時間を置いてから再度ご利用ください。</Typography>
        </Stack>
        <Container style={{ textAlign: 'center' }}>
          <Button
            variant="contained"
            onClick={() => {
              logout({
                logoutParams: {
                  returnTo: TOP_BASE_URL,
                },
              });
            }}
          >
            ログアウト
          </Button>
        </Container>
      </Stack>
    );

  // ローディングが完了した場合、子コンポーネントを表示
  return <TemplatesContext.Provider value={value}>{children}</TemplatesContext.Provider>;
};
