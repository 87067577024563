import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { AppBar, Box, Container, IconButton, Link, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';

const links = [
  {
    title: 'ダッシュボード',
    url: '/',
  },
];

export const Header: React.FC<{
  showLoginButton?: boolean;
}> = ({ showLoginButton = true }) => {
  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (event: React.MouseEvent<HTMLElement>, url?: string) => {
    setAnchorElNav(null);
    if (url) transit(url);
  };

  const transit = (url: string) => {
    navigate(url);
  };

  return (
    <AppBar position="static" color="inherit">
      <Container maxWidth="xl">
        <Toolbar disableGutters style={{ justifyContent: 'space-between' }}>
          {/* PC用ロゴ */}
          <Typography
            variant="subtitle1"
            noWrap
            component="a"
            onClick={() => transit('/')}
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              color: 'inherit',
              textDecoration: 'none',
              cursor: 'pointer',
            }}
          >
            SheetX
          </Typography>

          {/* PC用メニュー */}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {links.map((link) => (
              <Link
                key={link.title}
                component={RouterLink}
                to={link.url}
                underline="none"
                aria-label="link of transition to dashboard"
                color="inherit"
                fontSize="14px"
              >
                {link.title}
              </Link>
            ))}
          </Box>

          {/* モバイルメニュー */}
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            {showLoginButton && (
              <>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={(ev: React.MouseEvent<HTMLElement>) => handleCloseNavMenu(ev)}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  {links.map((link) => (
                    <MenuItem key={link.title} onClick={(ev) => handleCloseNavMenu(ev, link.url)}>
                      <Typography textAlign="center">{link.title}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
          </Box>

          {/* モバイル用ロゴ */}
          <Typography
            variant="subtitle1"
            noWrap
            component="a"
            onClick={() => transit('/')}
            sx={{
              display: { xs: 'inline-block', md: 'none' },
              color: 'inherit',
              textDecoration: 'none',
              cursor: 'pointer',
            }}
          >
            SheetX
          </Typography>

          {/* ログアウトリンク */}
          {showLoginButton ? (
            <Box sx={{ flexGrow: 0 }}>
              <Link
                component={RouterLink}
                to="/logout"
                underline="none"
                aria-label="link of logout"
                color="inherit"
                fontSize="14px"
              >
                ログアウト
              </Link>
            </Box>
          ) : (
            <Box sx={{ flexGrow: 0 }} />
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
