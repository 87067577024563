import { useEffect, useState } from 'react';

// mui components
import { Box, Container, Stack, Tab, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

// others
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { JsonViewer } from '@textea/json-viewer';

// components
import { CopyButton } from '../../../components/CopyButton';

// config
import { SHEETX_API_BASE_URL } from '../../../config/env';
import { Template } from '../../../config/types';

type TabName = 'schema' | 'curl';
type SnippetType = 'requestHeader' | 'requestBody' | 'curl';

/**
 * 言語ごとのスニペットを表示するダイアログのボディ部分
 * - 言語ごとのスニペットをタブで切り替える
 * - タブパネルの高さは、ダイアログの高さに合わせて自動的に調整する
 */
export const APISpecificationDialogBody: React.FC<{ template: Template; apiKey: string }> = ({ template, apiKey }) => {
  const [tabName, setTabName] = useState<TabName>('schema');
  const [tabPanelMaxHeight, setTabPanelMaxHeight] = useState<number>(0);

  const dialogMarginTop = 32;
  const dialogMarginBottom = 32;
  const dialogTitleHeight = 64;
  const tabsBarHeight = 48;

  /**
   * ウィンドウの高さが変更されたら、タブの高さを再計算する
   */
  useEffect(() => {
    const resize = () => {
      // タブパネルのmaxHeight = windowの高さ - ダイアログの上下のマージン - ダイアログのタイトル行の高さ - タブのバーの高さ
      const height = window.innerHeight - dialogMarginTop - dialogMarginBottom - dialogTitleHeight - tabsBarHeight;
      setTabPanelMaxHeight(height);
    };
    window.addEventListener('resize', resize);
    resize();

    return () => {
      window.removeEventListener('resize', resize);
    };
  });

  // タブを切り替える
  const handleChange = (event: React.SyntheticEvent, newTabName: TabName) => setTabName(newTabName);

  const createSchemaSnippet = () => {
    const replacementValueObj: { [key in string]: string } = {};
    template.replacements.forEach((replacement) => {
      replacementValueObj[replacement] = '';
    });

    return {
      response_type: '',
      sheets: [
        {
          template_id: '',
          page_number: 1,
          values: replacementValueObj,
        },
      ],
    };
  };

  /**
   * 帳票生成APIを呼び出すためのCurlコマンド文字列を生成
   */
  const createCurlSnippet = () => {
    const replacementValueObj: { [key in string]: string } = {};
    template.replacements.forEach((replacement) => {
      replacementValueObj[replacement] = `●●●`;
    });
    const headers = {
      'content-type': 'application/json',
      'x-api-key': apiKey,
    };
    const body = {
      response_type: 'url',
      sheets: [
        {
          template_id: template.template_id,
          page_number: 1,
          values: replacementValueObj,
        },
      ],
    };
    const headersString = Object.entries(headers)
      .map(([key, value]) => `-H "${key}: ${value}" \\`)
      .join('\n');
    const bodyString = JSON.stringify(body, null, 2)
      .split('\n')
      .map((line) => `  ${line}`) // Indent each line of the JSON body
      .join('\n');
    return `curl -X POST \\\n${headersString}\n-d '${bodyString.trim()}' \\\n${SHEETX_API_BASE_URL}/sheets`;
  };

  /**
   * スニペット名に応じたスニペット文字列を返す
   */
  const getSnippetText = (snippetType: SnippetType) => {
    if (snippetType === 'requestHeader') {
      return JSON.stringify({ 'x-api-key': '', 'content-type': '' }, null, 2);
    }

    if (snippetType === 'requestBody') {
      return JSON.stringify(createSchemaSnippet(), null, 2);
    }
    if (snippetType === 'curl') {
      return createCurlSnippet();
    }
    return '';
  };

  return (
    <Box>
      <TabContext value={tabName}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', height: `${tabsBarHeight}px` }}>
          <TabList onChange={handleChange} aria-label="API snippet tabs">
            <Tab label="API schema" value="schema" />
            <Tab label="curl" value="curl" />
          </TabList>
        </Box>

        <Container
          sx={{ overflowY: 'auto', maxHeight: `${tabPanelMaxHeight}px`, paddingTop: '8px', paddingBottom: '24px' }}
        >
          <TabPanel value="schema" style={{ padding: 0 }}>
            <Stack spacing={2}>
              <Stack spacing={2}>
                <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="subtitle2">Request Header</Typography>
                  <CopyButton text={getSnippetText('requestHeader')} />
                </Stack>
                <JsonViewer
                  className="request-schema-viewer"
                  value={{
                    'x-api-key': '',
                    'content-type': '',
                  }}
                  displaySize={false}
                  rootName={false}
                  enableClipboard={false}
                  style={{ backgroundColor: 'whitesmoke', padding: '8px' }}
                />
              </Stack>

              <Stack spacing={2}>
                <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="subtitle2">Request Body</Typography>
                  <CopyButton text={getSnippetText('requestBody')} />
                </Stack>
                <JsonViewer
                  className="request-schema-viewer"
                  value={createSchemaSnippet()}
                  displaySize={false}
                  rootName={false}
                  enableClipboard={false}
                  maxDisplayLength={template.replacements.length || 30} // 置換項目数分だけ表示し、置換項目がない場合は30項目まで表示する
                  style={{ backgroundColor: 'whitesmoke', padding: '8px' }}
                />
              </Stack>
            </Stack>
          </TabPanel>
          <TabPanel value="curl" style={{ padding: 0 }}>
            <Stack spacing={2}>
              <Stack flexDirection="row" justifyContent="flex-end" alignItems="center">
                <CopyButton text={getSnippetText('curl')} />
              </Stack>
              <SyntaxHighlighter language="bash" style={a11yDark} wrapLongLines>
                {createCurlSnippet()}
              </SyntaxHighlighter>
            </Stack>
          </TabPanel>
        </Container>
      </TabContext>
    </Box>
  );
};
