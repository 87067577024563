import { useContext, useRef } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useAuth0 } from '@auth0/auth0-react';

// mui components
import {
  Box,
  Button,
  Container,
  FormControl,
  TextField,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { Close, InfoOutlined } from '@mui/icons-material';

// providers
import { LoadingContext } from '../../../providers/loadingProvider';
import { TemplatesContext } from '../../../providers/templatesProvider';
import { UserContext } from '../../../providers/userProvider';

// functions
import { uploadSourceFile } from '../functions/uploadSourceFile';

// config
import { MANAGEMENT_API_IDENTIFIER } from '../../../config/env';
import { Size, Orientation } from '../../../config/types';

type TemplateCreateInputs = {
  templateName: string;
  sourceFile: File | null;
  size: Size;
  orientation: Orientation;
};

/**
 * 帳票テンプレート作成フォーム
 * - 帳票テンプレート名、帳票イメージファイルを入力
 * - ファイルは10MB以下のみ許可
 */
export const TemplateCreateForm: React.FC<{ onCancel: () => void; onSuccess: () => void }> = ({
  onCancel,
  onSuccess,
}) => {
  // auth0 Providerを読込
  const { getAccessTokenSilently } = useAuth0();
  // contexts
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const { createTemplate, deleteTemplate } = useContext(TemplatesContext);

  const fileInputRef = useRef<HTMLInputElement>();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    setError,
    clearErrors,
  } = useForm<TemplateCreateInputs>({
    defaultValues: { templateName: '', sourceFile: null, size: 'A4', orientation: 'portrait' },
  });

  /**
   * ファイル選択時の処理
   * - 選択されたファイルをstateに保存
   * - 10MBを超える場合はエラーメッセージを表示
   */
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // エラーをクリア
    clearErrors('sourceFile');

    const selected = e.target.files?.[0];
    if (selected) {
      // 10MBを超える場合はエラーメッセージを表示
      if (selected.size > 1024 * 1024 * 10) {
        setError('sourceFile', { message: 'ファイルサイズは10MB以下にしてください' });
      }
      setValue('sourceFile', selected);
    }
  };

  /**
   * サイズと向き選択時の処理
   */
  const handleSizeAndOrientationSelectChange = (val: string) => {
    const [size, orientation] = val.split(',');
    setValue('size', size as Size);
    setValue('orientation', orientation as Orientation);
  };

  /**
   * テンプレート作成時の処理
   * - テンプレートデータをDBに保存
   * - 帳票イメージアップロード用RULを取得
   * - 帳票イメージをアップロード
   * - テンプレート一覧を更新
   * - モーダルを閉じる
   */
  const onSubmit: SubmitHandler<TemplateCreateInputs> = async (data: TemplateCreateInputs) => {
    const { templateName, size, orientation, sourceFile } = data;

    if (!sourceFile) {
      return setError('sourceFile', { message: 'ファイルを選択してください' });
    }

    try {
      showLoading('帳票テンプレートを作成しています...');

      // テンプレートデータをDBに保存
      const createTemplateRes = await createTemplate(templateName, size, orientation);
      if (createTemplateRes.isFailure()) {
        return alert('テンプレートの作成に失敗しました。\n時間をおいて再度お試しください。');
      }
      const template = createTemplateRes.value;

      showLoading('帳票イメージをアップロードしています...');

      // アクセストークンを取得
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: MANAGEMENT_API_IDENTIFIER,
        },
      });

      // 帳票イメージアップロード用RULを取得
      const uploadSourceFileRes = await uploadSourceFile(sourceFile, template.template_id, accessToken, (percent) => {
        showLoading(`帳票イメージをアップロードしています... ${percent}%`);
      });
      if (uploadSourceFileRes.isFailure()) {
        // 失敗した場合はテンプレートを削除
        await deleteTemplate(template.template_id);
        return alert('帳票イメージのアップロードに失敗しました。\n時間をおいて再度お試しください。');
      }

      // 親コンポーネントの処理を実行
      onSuccess();

      return console.log('completed create template');
    } finally {
      hideLoading();
    }
  };

  /**
   * ファイル名を先頭5文字、最後5文字にトリミングする
   * @param fileName ファイル名
   * @returns "先頭5文字" + "..." + "最後5文字" + "拡張子"
   */
  const truncateFileName = (fileName: string | undefined) => {
    if (!fileName) return '';
    if (fileName.length <= 10) {
      return fileName;
    }
    const extensionIndex = fileName.lastIndexOf('.');
    if (extensionIndex > -1) {
      const nameWithoutExtension = fileName.slice(0, extensionIndex);
      const truncatedName = `${nameWithoutExtension.slice(0, 5)}...${nameWithoutExtension.slice(-5)}`;
      const extension = fileName.slice(extensionIndex);
      return truncatedName + extension;
    }
    return `${fileName.slice(0, 5)}...${fileName.slice(-5)}`;
  };

  return (
    <Stack component="form" noValidate onSubmit={handleSubmit(onSubmit)} direction="column" spacing={2} py={2}>
      <Container>
        <Stack direction="column" spacing={3}>
          {/* 帳票テンプレート名 */}
          <Controller
            name="templateName"
            control={control}
            rules={{
              required: '帳票テンプレート名を入力してください',
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                type="text"
                label="帳票テンプレート名"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                fullWidth
                size="small"
              />
            )}
          />
          {/* 帳票イメージ */}
          <Box mt={4} position="relative">
            {/* 帳票イメージのデータを管理するInput、デザインはカスタマイズするため、Input自体は非表示 */}
            <Input
              fullWidth
              ref={fileInputRef}
              type="file"
              size="small"
              style={{ display: 'none' }}
              onChange={handleFileChange}
              onClick={() => {
                // 同じファイルを選択した場合、onChangeが発火しないため、クリアする
                if (fileInputRef.current) {
                  const input = fileInputRef.current.children[0] as HTMLInputElement;
                  input.value = '';
                }
              }}
            />
            {/* 帳票イメージのInputのデザインを管理するコンポーネント */}
            <Stack direction="row" position="relative">
              {/* TextFieldを用いて、他の入力フォームのデザインと同じデザインにする */}
              <TextField
                fullWidth
                label="帳票イメージ"
                placeholder="クリックしてファイルを選択"
                value={truncateFileName(watch('sourceFile')?.name)}
                // エラーがある場合は赤枠とエラーメッセージを表示
                error={!!errors.sourceFile}
                helperText={errors.sourceFile?.message}
                size="small"
                // ラベルを左上に表示
                InputLabelProps={{ shrink: true }}
                inputProps={{ sx: { cursor: 'pointer' } }}
                // TextFieldのイベントを無効化にし、ファイル選択のイベントを発火させる
                onMouseDown={(e) => {
                  e.preventDefault();
                  if (fileInputRef.current) {
                    const input = fileInputRef.current.children[0] as HTMLInputElement;
                    input.click();
                  }
                }}
              />
              {/* ファイル選択解除ボタン、ファイル選択済の場合のみ表示する */}
              {watch('sourceFile') && (
                <IconButton
                  style={{ position: 'absolute', right: '5px' }}
                  onClick={() => {
                    // 選択されているファイルをクリア
                    if (fileInputRef.current) {
                      const input = fileInputRef.current?.children[0] as HTMLInputElement;
                      input.value = '';
                      setValue('sourceFile', null);
                      // エラーをクリア
                      clearErrors('sourceFile');
                    }
                  }}
                >
                  <Close />
                </IconButton>
              )}
            </Stack>
            <Stack flexDirection="row" alignItems="center" mt={1} color="currentcolor">
              <InfoOutlined fontSize="small" />
              <Typography component="span" fontSize="small" ml={0.5} letterSpacing={0}>
                帳票の例やフォーマットをアップロードしてください
              </Typography>
            </Stack>
          </Box>
          {/* サイズと向き */}
          <FormControl>
            <InputLabel id="size-and-orientation-select-label">帳票サイズ</InputLabel>
            <Select
              labelId="size-and-orientation-select-label"
              id="size-and-orientation-select"
              label="帳票サイズ"
              value={`${watch('size').valueOf()},${watch('orientation').valueOf()}`}
              onChange={(e) => handleSizeAndOrientationSelectChange(e.target.value)}
              size="small"
            >
              <MenuItem value="A4,portrait">A4縦</MenuItem>
              <MenuItem value="A4,landscape">A4横</MenuItem>
              <MenuItem value="A5,portrait">A5縦</MenuItem>
              <MenuItem value="A5,landscape">A5横</MenuItem>
              <MenuItem value="A3,portrait">A3縦</MenuItem>
              <MenuItem value="A3,landscape">A3横</MenuItem>
            </Select>
          </FormControl>

          <Stack
            flexDirection={{ sm: 'row', xs: 'column-reverse' }}
            justifyContent={{ sm: 'flex-end', xs: 'flex-start' }}
            alignItems={{ sm: 'center', xs: 'center' }}
          >
            <Button
              variant="outlined"
              style={{ minWidth: '100px', margin: '8px' }}
              onClick={() => {
                if (onCancel) {
                  onCancel();
                }
              }}
            >
              キャンセル
            </Button>
            <Button variant="contained" type="submit" style={{ minWidth: '100px' }}>
              作成
            </Button>
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};
