import { Auth0Provider as Provider, AppState } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

import { AUTH0_DOMAIN, AUTH0_CLIENT_ID } from '../config/env';

/**
 * @auth0-reactをカスタマイズしたProvider
 * - onRedirectCallback: ログイン後のリダイレクト先を設定する
 */
export const Auth0Provider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    const returnTo = appState?.returnTo;
    if (returnTo === '/login' || returnTo === '/signup') {
      navigate('/', { replace: true });
    } else {
      navigate(appState?.returnTo || '/');
    }
  };

  return (
    <Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Provider>
  );
};
