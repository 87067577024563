/**
 * エラーハンドリング用の型定義
 * 関数の戻り値を、ProcessSuccessまたはProcessFailureでラップして呼び出し元に返す
 * 呼び出し元で、isSuccess()またはisFailure()で処理結果を判定し、処理を分岐させる
 * 使い方: https://qiita.com/Kodak_tmo/items/d48eb3497be18896b999
 */
export type ProcessResult<T, E extends Error> = ProcessSuccess<T> | ProcessFailure<E>;

export class ProcessSuccess<T> {
  readonly value: T;

  constructor(value: T) {
    this.value = value;
  }

  isSuccess(): this is ProcessSuccess<T> {
    return true;
  }

  isFailure(): this is ProcessFailure<Error> {
    return false;
  }
}

export class ProcessFailure<E extends Error> {
  readonly error: E;

  constructor(error: E) {
    this.error = error;
  }

  isSuccess(): this is ProcessSuccess<unknown> {
    return false;
  }

  isFailure(): this is ProcessFailure<E> {
    return true;
  }
}

export class ErrorResponse extends Error {
  readonly name: string;

  readonly message: string;

  readonly stack?: string;

  constructor(
    readonly functionName: string = 'unknown functionName',
    readonly statusCode: number = 500,
    readonly code: string = 'APP_UNKNOWN_ERROR',
    readonly error: Error = new Error('unknown error')
  ) {
    console.error('occur error: ', JSON.stringify({ functionName, statusCode, code, errorStack: error.stack }));

    super();
    this.name = error.name;
    this.message = error.message;
    this.stack = error.stack;
  }
}
