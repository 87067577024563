import { createContext, useCallback, useMemo, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';

// contexts
export const LoadingContext = createContext({
  loading: false,
  loadingMessage: '処理中...',
  showLoading: (loadingMessage?: string) => {
    /* Do nothing because of default definitions */
  },
  hideLoading: () => {
    /* Do nothing because of default definitions */
  },
});
/**
 * ローディング状態を管理するprovider
 * isLoadingがtrueのときにLoadingOverlayを表示する
 */
export const LoadingProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('処理中...');

  // ローディング表示
  const showLoading = useCallback((_loadingMessage?: string) => {
    setLoading(true);
    if (_loadingMessage) {
      setLoadingMessage(_loadingMessage);
    } else {
      setLoadingMessage('処理中...');
    }
  }, []);

  // ローディング非表示
  const hideLoading = useCallback(() => {
    setLoading(false);
  }, []);

  // Providerのvalueに渡すオブジェクトをメモ化
  const value = useMemo(
    () => ({ loading, loadingMessage, showLoading, hideLoading }),
    [loading, loadingMessage, showLoading, hideLoading]
  );

  return (
    <LoadingContext.Provider value={value}>
      <LoadingOverlay
        active={loading}
        spinner
        text={loadingMessage}
        fadeSpeed={0}
        styles={{ wrapper: { overflowY: 'auto' } }}
      >
        {children}
      </LoadingOverlay>
    </LoadingContext.Provider>
  );
};
