import { useState } from 'react';

import { IconButton, IconButtonProps } from '@mui/material';
import { AssignmentTurnedIn, Check, ContentCopy } from '@mui/icons-material';

import { CopyToClipboard } from 'react-copy-to-clipboard';

type CopyButtonProps = IconButtonProps & {
  text: string;
  copiedDuration?: number;
};

/**
 * クリックするとテキストをコピーするアイコン
 */
export const CopyIcon: React.FC<CopyButtonProps> = ({ text, copiedDuration = 1500, ...props }) => {
  const [copied, setCopied] = useState<boolean>(false);

  return (
    <CopyToClipboard
      text={text}
      onCopy={() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, copiedDuration);
      }}
    >
      <IconButton color="primary" {...props}>
        {copied ? <Check fontSize="small" /> : <ContentCopy fontSize="small" />}
      </IconButton>
    </CopyToClipboard>
  );
};
