// top environment variables
const TOP_BASE_URL = process.env.REACT_APP_TOP_BASE_URL!;

// app environment variables
const APP_ENV = process.env.REACT_APP_ENV!;
const APP_BASE_URL = process.env.REACT_APP_BASE_URL!;

// api environment variables
const SHEETX_API_BASE_URL = process.env.REACT_APP_SHEETX_API_BASE_URL!;
const DASHBOARD_API_BASE_URL = process.env.REACT_APP_DASHBOARD_API_BASE_URL!;

// auth0 environment variables
const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN!;
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID!;
const MANAGEMENT_API_IDENTIFIER = process.env.REACT_APP_MANAGEMENT_API_IDENTIFIER!;

// graphql environment variables
const GRAPHQL_API_ENDPOINT = process.env.REACT_APP_GRAPHQL_API_ENDPOINT!;

export {
  TOP_BASE_URL,
  APP_ENV,
  APP_BASE_URL,
  SHEETX_API_BASE_URL,
  DASHBOARD_API_BASE_URL,
  AUTH0_DOMAIN,
  AUTH0_CLIENT_ID,
  MANAGEMENT_API_IDENTIFIER,
  GRAPHQL_API_ENDPOINT,
};
