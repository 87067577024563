import { useState } from 'react';
import { Button } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';

/**
 * コピーボタンを表示する
 * @param text コピーするテキスト
 * @param copiedDuration コピー後に表示するテキストを表示する時間
 * @returns
 */
export const CopyButton: React.FC<{ text: string; copiedDuration?: number }> = ({ text, copiedDuration = 1500 }) => {
  const [copied, setCopied] = useState<boolean>(false);

  return (
    <CopyToClipboard
      text={text}
      onCopy={() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, copiedDuration);
      }}
    >
      <Button size="small" variant={copied ? 'outlined' : 'contained'} style={{ minWidth: '70px' }}>
        {copied ? 'Copied' : 'Copy'}
      </Button>
    </CopyToClipboard>
  );
};
