import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';

// Material UIのデフォルトフォントをimportする
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(<App />);
}
