import { useLocation } from 'react-router-dom';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';

// mui components
import { Box, Container, Stack } from '@mui/material';

// libraries

// components
import { Header } from './Header';

// providers
import { UserProvider } from '../providers/userProvider';
import { TemplatesProvider } from '../providers/templatesProvider';

/**
 * ログインを必須にするコンポーネント
 * @param component ログイン後に表示するコンポーネント
 */
export const ProtectedComponent: React.FC<{
  Component: React.ComponentType<object>;
  screenHint?: 'signup' | 'login';
}> = ({ Component, screenHint = 'login' }) => {
  // urlからパスを取得する
  const { pathname } = useLocation();
  // 認証情報を取得する
  const { isLoading, user } = useAuth0();

  // コンポーネントをログイン必須コンポーネントに変換
  const AuthenticationRequiredComponent = withAuthenticationRequired(() => <Component />, {
    loginOptions: {
      authorizationParams: {
        screen_hint: screenHint,
      },
    },
  });

  // auth0からユーザーの認証情報取得中の場合、nullを返す
  if (isLoading) return null;

  return (
    <Stack direction="column">
      {/* 以下のいずれかの条件を満たすとき、ヘッダーを非表示
        - pathnameが、認証関連（/signup, /login）
        - 認証情報がない
      */}
      {pathname.match(/(signup|login)/) || !user ? null : (
        <Box>
          <Header />
        </Box>
      )}
      <Box flexGrow={1} py={4}>
        <Container>
          <UserProvider>
            <TemplatesProvider>
              <AuthenticationRequiredComponent />
            </TemplatesProvider>
          </UserProvider>
        </Container>
      </Box>
    </Stack>
  );
};
