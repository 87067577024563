import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

// mui components
import { Button, Container, Link, Stack, Typography } from '@mui/material';
import { Launch } from '@mui/icons-material';
import { Image } from 'mui-image';

// others
import axios from 'axios';

// components
import { Dialog } from '../../components/Dialog';
import { APISpecificationDialogBody } from './components/APISpecificationDialogBody';

// providers
import { TemplatesContext } from '../../providers/templatesProvider';
import { UserContext } from '../../providers/userProvider';
import { LoadingContext } from '../../providers/loadingProvider';

// config
import { DASHBOARD_API_BASE_URL, MANAGEMENT_API_IDENTIFIER } from '../../config/env';
import { Template as TemplateType } from '../../config/types';

import './style.css';

/**
 * テンプレートページ
 */
export const Template: React.FC = () => {
  // API仕様を表示するためのモーダル表示用のstate
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const templateId = useParams().template_id || '';

  const { getAccessTokenSilently } = useAuth0();

  // ユーザーProviderを読込
  const { user } = useContext(UserContext);

  // テンプレートProviderを読込、パスパラメータからテンプレートIDを取得
  const { getTemplate } = useContext(TemplatesContext);
  const template = getTemplate(templateId);

  // テンプレートを取得するためのURL
  const [templateUrl, setTemplateUrl] = useState<string>('');
  const [loadTemplateError, setLoadTemplateError] = useState<boolean>(false);
  const [notFoundTemplateError, setNotFoundTemplateError] = useState<boolean>(false);

  // ローディングProviderを読込
  const { showLoading, hideLoading } = useContext(LoadingContext);
  // 画面初期化完了を管理するstate
  const [initialized, setInitialized] = useState(false);

  /**
   * コンポーネント描画時の処理
   * - テンプレートのSVGを取得するためのURLを取得
   */
  useEffect(() => {
    if (initialized) return;
    if (loadTemplateError) return;
    if (templateUrl) return;
    if (!user || !user.api_keys.length) {
      return;
    }
    if (!template) {
      setNotFoundTemplateError(true);
      return;
    }

    const initialize = async (_template: TemplateType) => {
      // テンプレートのステータスが、利用可能の場合のみ、ローディングを表示して、テンプレート画像を取得
      if (_template.status === 'available') {
        try {
          showLoading();

          // アクセストークンを取得
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: MANAGEMENT_API_IDENTIFIER,
            },
          });
          // 帳票テンプレートSVGを取得
          const res = await axios.post<{
            url: string;
          }>(
            `${DASHBOARD_API_BASE_URL}/templates/${templateId}/get-download-url`,
            {},
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                'content-type': 'application/json',
              },
            }
          );

          setTemplateUrl(res.data.url);
        } finally {
          hideLoading();
        }
      }
      setInitialized(true);
    };

    if (template) {
      initialize(template).catch((err) => {
        console.error(err);
        setLoadTemplateError(true);
      });
    }
  }, [
    initialized,
    loadTemplateError,
    templateUrl,
    template,
    templateId,
    user,
    getAccessTokenSilently,
    showLoading,
    hideLoading,
  ]);

  // テンプレートが取得できなかった場合に、エラーメッセージを表示
  if (loadTemplateError) {
    return (
      <Stack direction="column" maxWidth="sm" mx="auto" spacing={4}>
        <Stack direction="column" spacing={2}>
          <Typography>プレビューを表示できませんでした。</Typography>
        </Stack>
        <Container style={{ textAlign: 'center' }}>
          <Button variant="contained" onClick={() => navigate('/')}>
            ダッシュボードに戻る
          </Button>
        </Container>
      </Stack>
    );
  }

  // テンプレートが見つからなかった場合、エラーメッセージを表示
  if (notFoundTemplateError) {
    return (
      <Stack direction="column" maxWidth="sm" mx="auto" spacing={4}>
        <Stack direction="column" spacing={2}>
          <Typography>テンプレートが見つかりません。</Typography>
        </Stack>
        <Container style={{ textAlign: 'center' }}>
          <Button variant="contained" onClick={() => navigate('/')}>
            ダッシュボードに戻る
          </Button>
        </Container>
      </Stack>
    );
  }

  // 画面初期化中の場合、何も表示しない
  if (!initialized) return null;
  if (!template) return null;

  // テンプレートが見つかった場合、テンプレートの仕様を表示
  return (
    <Stack maxWidth="sm" mx="auto" spacing={2}>
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" component="h2" fontWeight="bold">
          {template.name}
        </Typography>
      </Stack>

      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <Stack flexDirection="row">
          <Button variant="contained" onClick={() => setOpen(true)}>
            API仕様
          </Button>

          {/* API仕様モーダル */}
          <Dialog maxWidth="md" fullWidth open={open} handleClose={() => setOpen(false)}>
            <APISpecificationDialogBody
              template={template}
              apiKey={user?.api_keys.length ? user.api_keys[0] : '{your_api_key}'}
            />
          </Dialog>

          <Stack flexDirection="row" alignItems="flex-end" ml={2}>
            <Link
              href="https://sheetx.app/docs/api#operation/post-sheets"
              target="_blank"
              underline="none"
              aria-label="API Document"
              alignItems="center"
              fontSize="small"
            >
              APIドキュメント
              <Launch fontSize="inherit" />
            </Link>
          </Stack>
        </Stack>
      </Stack>

      <Stack spacing={2}>
        {/* テンプレートの状態によって、表示を切替 */}
        {template.status === 'available' && templateUrl && (
          <Image
            src={`${templateUrl}`}
            showLoading
            duration={0}
            style={{ border: '1px solid black' }}
            onError={() => {
              setLoadTemplateError(true);
            }}
            errorIcon={null}
          />
        )}
        {template.status === 'processing' && (
          <Typography>
            テンプレートは現在作成中です。
            <br />
            完了次第ご連絡いたします。
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
