import './App.css';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme/theme';

// providers
import { LoadingProvider } from './providers/loadingProvider';
import { Auth0Provider } from './providers/customAuth0Provider';

// pages
import { Dashboard } from './pages/Dashboard/Index';
import { Template } from './pages/Template/Index';

import { Login, Signup, Logout } from './pages/Auth/Index';

// error pages
import { NoEntry, NotFound } from './pages/Error/index';

import { ProtectedComponent } from './components/protectedComponent';

import { GRAPHQL_API_ENDPOINT } from './config/env';

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  uri: GRAPHQL_API_ENDPOINT,
});

/**
 * Reactのルートコンポーネント
 * ProviderとRouterを設定する
 * - Provider
 *  - ThemeProvider: Material UIを使用するためのProvider
 *  - Auth0Provider: Auth0を使用するためのProvider
 * - Router
 * - ProtectedComponent: 認証が必要なコンポーネントをラップする
 * @returns
 */
const App = () => (
  <ThemeProvider theme={theme}>
    {/* CssBaselineは、ブラウザごとのデフォルトスタイルを調整する */}
    <CssBaseline />
    <LoadingProvider>
      <BrowserRouter>
        <ApolloProvider client={apolloClient}>
          <Auth0Provider>
            <Routes>
              <Route path="/" element={<ProtectedComponent Component={Dashboard} />} />
              <Route path="/templates/:template_id" element={<ProtectedComponent Component={Template} />} />
              <Route path="/login" element={<ProtectedComponent Component={Login} />} />
              <Route path="/signup" element={<ProtectedComponent Component={Signup} screenHint="signup" />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/error/no-entry" element={<ProtectedComponent Component={NoEntry} />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Auth0Provider>
        </ApolloProvider>
      </BrowserRouter>
    </LoadingProvider>
  </ThemeProvider>
);

export default App;
