import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

import { TOP_BASE_URL } from '../../config/env';
import { LoadingContext } from '../../providers/loadingProvider';

/**
 * ログインページ
 * /loginにアクセスされたときは、/にリダイレクトする
 */
export const Login: React.FC = () => {
  const navigate = useNavigate();
  const { showLoading, hideLoading } = useContext(LoadingContext);

  useEffect(() => {
    try {
      showLoading();
      navigate('/', { replace: true });
    } finally {
      hideLoading();
    }
  });

  return null;
};

/**
 * サインアップページ
 * /signupにアクセスされたときは、/にリダイレクトする
 * @returns
 */
export const Signup: React.FC = () => {
  const navigate = useNavigate();
  const { showLoading, hideLoading } = useContext(LoadingContext);

  useEffect(() => {
    try {
      showLoading();
      navigate('/', { replace: true });
    } finally {
      hideLoading();
    }
  });

  return null;
};

/**
 * ログアウトページ
 * /logoutにアクセスされたときは、sheetx.appにリダイレクトする
 */
export const Logout: React.FC = () => {
  const { logout } = useAuth0();
  const { showLoading, hideLoading } = useContext(LoadingContext);

  useEffect(() => {
    try {
      showLoading();

      logout({
        logoutParams: {
          returnTo: TOP_BASE_URL,
        },
      });
    } finally {
      hideLoading();
    }
  });

  return null;
};
