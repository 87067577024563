import { Box, Container, Stack, Typography } from '@mui/material';
import { Header } from '../../components/Header';

/**
 * 404ページ
 */
export const NotFound: React.FC = () => (
  <>
    <Header showLoginButton={false} />
    <Stack direction="column" maxWidth="sm" mx="auto">
      <Box flexGrow={1} py={4}>
        <Container>
          <Typography>ページが見つかりません。</Typography>
        </Container>
      </Box>
    </Stack>
  </>
);
