import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Typography, Stack } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { UserContext } from '../../providers/userProvider';
import { LoadingContext } from '../../providers/loadingProvider';

const FORM_URL = 'https://share.hsforms.com/1suO3Pf7BQ8Gle7aoAake0Qnl90t';

/**
 * 試用未申し込みページ
 */
export const NoEntry: React.FC = () => {
  const navigate = useNavigate();
  const { user: auth0User } = useAuth0();
  const { user } = useContext(UserContext);

  const { showLoading, hideLoading } = useContext(LoadingContext);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (initialized) return;

    try {
      showLoading();

      // プランがNoneでない場合はダッシュボードへ遷移
      if (user && user.plan !== 'None') {
        setInitialized(true);
        navigate('/', { replace: true });
      } else if (user && user.plan === 'None') {
        setInitialized(true);
      }
    } catch (error) {
      setInitialized(true);
    } finally {
      hideLoading();
    }
  }, [navigate, user, showLoading, hideLoading, initialized]);

  if (!initialized) return null;

  const email = auth0User?.email || '';
  const formUrl = `${FORM_URL}?email=${email}`;

  return (
    <Stack direction="column" maxWidth="sm" mx="auto" spacing={4}>
      <Stack direction="column" spacing={2}>
        <Typography variant="h5" fontWeight="bold">
          お客様の環境を準備中です。
        </Typography>
        <Typography>試用の申し込みがお済みでない方は、以下よりご登録ください。</Typography>
      </Stack>
      <Container style={{ textAlign: 'center' }}>
        <Button href={formUrl} target="_blank" variant="contained">
          無料試用の登録を行う
        </Button>
      </Container>
    </Stack>
  );
};
