import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

// mui components
import { Button, Container, Stack, Typography } from '@mui/material';

// libraries
import { useApolloClient, gql } from '@apollo/client';

// config
import { TOP_BASE_URL, MANAGEMENT_API_IDENTIFIER } from '../config/env';
import { Plan, User } from '../config/types';

// providers
import { LoadingContext } from './loadingProvider';

const initialUserPlan: Plan = 'None';

/**
 * Providerが、他のコンポーネントに共有するユーザー情報・関数
 */
export const UserContext = createContext<{
  user: User | undefined;
}>({
  user: undefined,
});

/**
 * 他のコンポーネントにユーザー情報を共有するためのProvider
 */
export const UserProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const apolloClient = useApolloClient();
  const { user: auth0User, logout, getAccessTokenSilently, error: auth0Error } = useAuth0();
  const { showLoading, hideLoading } = useContext(LoadingContext);

  const [user, setUser] = useState<User>();

  // useEffectの処理を管理するためのstate
  const [initialized, setInitialized] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    // auth0の認証情報が取得できていない場合、エラーを表示
    if (auth0Error) {
      setError(true);
      return;
    }
    if (!auth0User) return;
    if (error) return;
    if (user) return;
    if (initialized) return;

    // DBからユーザー情報を取得
    const fetchUser = async () => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: MANAGEMENT_API_IDENTIFIER,
        },
      });

      const option = {
        query: gql`
          query GetUser {
            getUser {
              user_id
              api_keys
              plan
              created_at
              updated_at
            }
          }
        `,
        context: {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        },
      };
      const { data, errors } = await apolloClient.query<{
        getUser: User | null;
      }>(option);

      if (errors) throw new Error(errors[0].message);

      return { user: data.getUser };
    };

    // DBにユーザー情報を登録
    const createUser = async () => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: MANAGEMENT_API_IDENTIFIER,
        },
      });

      const option = {
        mutation: gql`
          mutation CreateUser {
            createUser(input: { api_keys: [], plan: "${initialUserPlan}"}) {
              user_id
              api_keys
              plan
              created_at
              updated_at
            }
          }
        `,
        context: {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        },
      };

      const { data, errors } = await apolloClient.mutate<{
        createUser: User;
      }>(option);

      if (errors) throw new Error(errors[0].message);
      if (!data) throw new Error('ユーザーを作成できませんでした。');

      return { user: data.createUser };
    };

    const initialize = async () => {
      try {
        showLoading();

        const { user: fetchedUser } = await fetchUser();
        if (fetchedUser) {
          // DBにユーザー情報が存在する場合、そのユーザー情報をstateにセット
          setUser(fetchedUser);
        } else {
          // DBにユーザー情報が存在しない場合、新しいユーザーとしてDBに登録
          const { user: createdUser } = await createUser();
          // 新しいユーザー情報をstateにセット
          setUser(createdUser);
        }

        // 初期化完了
        setInitialized(true);
      } finally {
        hideLoading();
      }
    };

    initialize().catch((err) => {
      console.error(err);
      setError(true);
    });
  }, [error, apolloClient, getAccessTokenSilently, auth0User, auth0Error, user, initialized, showLoading, hideLoading]);

  /**
   * ユーザーのプランが"None"の場合、試用申込をさせるために、"/error/no-entry"にリダイレクトさせる
   */
  useEffect(() => {
    // ログインしていない場合、何もしない
    if (!user) return;
    // pathnameが"/error/no-entry"の場合、何もしない
    if (pathname === '/error/no-entry') return;
    // ユーザーのプランが"None"以外の場合、何もしない
    if (user.plan !== 'None') return;
    // Planが"None"のユーザーは、"/error/no-entry"にリダイレクトさせる
    navigate('/error/no-entry', { replace: true });
  }, [user, pathname, navigate]);

  // Providerのvalueに渡すオブジェクトをメモ化
  const value = useMemo(() => ({ user }), [user]);

  // エラーが発生した場合、エラー画面を表示
  if (error)
    return (
      <Stack direction="column" maxWidth="sm" mx="auto" spacing={4}>
        <Stack direction="column" spacing={2}>
          <Typography>ユーザー情報取得時、エラーが発生しました。</Typography>
          <Typography>時間を置いてから再度ご利用ください。</Typography>
        </Stack>
        <Container style={{ textAlign: 'center' }}>
          <Button
            variant="contained"
            onClick={() => {
              logout({
                logoutParams: {
                  returnTo: TOP_BASE_URL,
                },
              });
            }}
          >
            ログアウト
          </Button>
        </Container>
      </Stack>
    );

  // ユーザー情報が存在する場合、子コンポーネントを表示
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
